
import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator"
import {TrainDto, VolunteerDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";

@Component({
  name: "RoleList",
  components: {
    OrganizationSelect,
    PagedTableView,
    AbSelect
  }
})
export default class NotifyList extends Vue {
  tableItems: TrainDto[] = [];
  typeList: any = [];

  queryForm = {
    name: "",
    phoneNumber: null,
    organizationUnitId:undefined
  };


  // 获取表数据
  fetchData(params: any) {
    return api.volunteer.getAll(params);
  }
  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }
  auditPass(index: number, row: VolunteerDto) {
    api.volunteer.audit({body:{volunteerId:row.id,isPass:true}}).then((res) => {
      this.$message({
        type: "success",
        message: "操作成功!"
      });
      this.fetchData(this.queryForm);
    })
  }
  auditReject(index: number, row: VolunteerDto) {
    api.volunteer.audit({body:{volunteerId:row.id,isPass:false}}).then((res) => {
      this.$message({
        type: "success",
        message: "操作成功!"
      });
      this.fetchData(this.queryForm);
    })
  }
}
